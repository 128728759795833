import * as $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle';
import './contact'
import './scroll'
import desktop from './desktop';
import mobile from './mobile';

var width = window.innerWidth;

if (width < 1200) {
  $( "body" ).html(mobile);
} else {
  $( "body" ).html(desktop);
}

$(window).on("resize", function() {
  if ($(window).width() < 1200) {
    $( "body" ).html(mobile);
  } else {
    $( "body" ).html(desktop);
  }
});

