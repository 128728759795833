const desktop = `<main class="container" id="initial">
  <header class="header w-100">
    <div class="w-100 position-relative">
      <div class="header-bg-curve w-100 position-absolute">
        <div class="back-menu position-fixed"></div>
        <div class="menu position-fixed">
          <ul class="w-100">
            <div class="w-100 d-flex justify-content-center">
              <li id="btn-initial">Início</li>
              <li id="btn-advantages">Vantagens</li>
            </div>
            <div class="w-100 d-flex justify-content-center">
              <li id="btn-register">Adquira já seu plano</li>
            </div>
          </ul>
        </div>
        <div class="d-flex w-100 header-content">
          <div class="d-flex left-content justify-content-center flex-wrap">
            <div class="header-lines"></div>
            <img class="img-header-people" src="${require('../img/pessoa-fisica-header.png')}" alt="imagem-pessoa-com-cartao" title="Unimed Patos de Minas - Pessoa Física" />
            <div class="w-100 px-8">
              <p class="mt-5 ml-5 px-5 w-75">*Desconto gradativo somente na inclusão de dependentes. Consulte nossos consultores para mais informações.</p>
            </div>
          </div>
          <div class="d-flex right-content flex-wrap">
            <div class="mb-5">
              <img class="text-higthlight-header mt-5 mb-5" src="${require('../img/svg/text-header.svg')}" alt="Agora tem plano pessoa física tem tem" title="Agora tem plano pessoa física tem tem" />
              <h3 class="mb-4">Plano Pessoa Física</h3>
              <p class="mb-0">Para quem quer, tem. Para você e para todos.</p>
              <p>Plano individual para você se manter com a saúde em dia de forma fácil, simples e descomplicada. Quem tem unimed plano pessoa física ta com tudo.</p>
              <a id="btn-register-link">Faça agora seu plano</a>
            </div>
            <div class="w-100 mt-5">
              <img src="${require('../img/logo-unimed-patos-de-minas.png')}" alt="logomarca-unimed-patos-de-minas" title="Unimed Patos de Minas - Campanha Pessoa Física" />
            </div>
          </div>
        </div>
      </div>
      <div class="sub-bg w-100"></div>
    </div>
  </header>
  <section class="content" id="content">
    <div class="w-100 px-8 d-flex position-relative">
      <div class="content-lines"></div>
      <div class="physical-people-content position-absolute">
        <img src="${require('../img/pessoa-fisica-content.png')}" alt="Plano Fácil Pessoa Física" title="Plano fácil pessoa física" />
      </div>
      <div class="w-100 left-content">
        <h1 class="mt-4 mb-3">Você em primeiro<br />plano.</h1>
        <span>O foco é a singularidade da vida.</span>
        <ul>
          <li>Atendimento em Patos de Minas e região;</li>
          <li>Acomodação Enfermaria e Apartamento;</li>
          <li>Super desconto gradativo conforme inclusão de dependentes;</li>
          <li>Urgência e emergência nacional;</li>
          <li>Serviço de remoção aérea incluso.</li>
        </ul>
        <h4 class="mt-5">Consulte condições contratuais com nossos consultores.</h4>
      </div>
      <div class="right-content">
        <h2>Um plano<br />que é a<br />sua cara.</h2>
      </div>
    </div>
  </section>
  <footer class="footer" id="contact-form">
    <div class="w-100 px-8 d-flex position-relative">
      <div class="contact position-absolute">
        <div class="d-flex">
          <div class="w-25 left-contact-content mr-5">
            <img class="mt-5 mb-3 icon-branding" src="${require('../img/svg/icon-branding.svg')}" alt="icon-campanha-pessoa-fisica" title="Unimed Patos de Minas - Campanha Pessoa Física" />
            <h4 class="mt-3 contact-text-info">Preencha seus dados que logo entraremos em contato com você.</h4>
            <button id="send-email" class="btnSubmit btn-send mt-4">Enviar</button>
          </div>
          <form class="w-100" method="post" autocomplete="off" name="google-sheet">
            <div class="w-100 px-8 pt-3">
              <div class="form-group mt-3">
                <span>Nome*:</span>
                <input type="text" name="Name" class="form-control text-input mt-1" value="" required />
              </div>
              <div class="form-group mt-3">
                <span>Email*:</span>
                <input type="email" name="Email" class="form-control text-input mt-1" value="" required />
              </div>
              <div class="form-group mt-3">
                <span>Cidade:</span>
                <input type="email" name="Cidade" class="form-control text-input mt-1" value="" required />
              </div>
              <div class="form-group mt-3">
                <span>Telefone:</span>
                <input type="number" name="Phone" class="form-control text-input mt-1" value="" required />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="w-100 left-content d-flex align-items-end">
        <div class="w-100 d-flex flex-wrap">
          <h5 class="mb-4"><b>Unimed Patos de Minas</b></h5>
          <div class="w-100 d-flex">
            <div class="w-100">
              <p class="mb-2"><b>Unidade Comercial</b></p>
              <span>Endereço: R. Olegário Maciel, 413</span><br />
              <span>Centro, Patos de Minas - MG</span><br />
              <span>Telefone: (34) 3820-1622</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right-content d-flex align-items-end flex-wrap">
        <img class="mx-8 logo-branding" src="${require('../img/svg/logo-campanha.svg')}" alt="Unimed Patos de Minas - Campanha Pessoa Física" title="Unimed Patos de Minas - Campanha Pessoa Física" />
        <img class="mx-8 mb-4" src="${require('../img/logo-unimed-patos-de-minas.png')}" alt="logomarca-unimed-patos-de-minas" title="Unimed Patos de Minas - Campanha Pessoa Física" />
      </div>
    </div>
    <div class="copy w-100 d-flex align-items-center px-8">
      <div class="d-flex w-100 align-items-center justify-content-between">
        <img class="img-coop" src="${require('../img/logo-somos-coop.png')}" alt="Somos Coop" alt="Somos Coop" />
        <img class="img-ans" src="https://www.unimed.coop.br/servicos-compartilhados-portlet/ImagemANS?ans=353060" alt="ANS 353060" title="ANS 353060" />
      </div>
      <div class="d-flex w-100 justify-content-end">
        <p class="mt-3">Copyright 2001 - 2021 Unimed do Brasil - Todos os direitos reservados</p>
      </div>
    </div>
  </footer>
</main>`

export default desktop