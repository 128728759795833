const mobile = `
<main id="initial">
  <header class="header-mobile w-100 position-relative">
    <div class="header-background-mobile w-100 position-absolute d-flex flex-wrap">
      <div class="w-100">
        <div class="back-menu position-fixed"></div>
        <div class="menu position-fixed">
          <ul class="w-100">
            <div class="w-100 d-flex justify-content-center">
              <li id="btn-initial">Início</li>
              <li id="btn-advantages">Vantagens</li>
            </div>
            <div class="w-100 d-flex justify-content-center">
              <li id="btn-register">Adquira já seu plano</li>
            </div>
          </ul>
        </div>
      </div>
      <div class="left-content w-25 d-flex align-items-end">
       <img class="icon-mobile-branding p-3 w-100" src="${require('../img/svg/icon-branding-mobile.svg')}" alt="icon-campanha-pessoa-fisica" title="Unimed Patos de Minas - Campanha Pessoa Física" />
      </div>
      <div class="right-content w-75 p-5 d-flex flex-wrap">
        <img class="text-higthlight-header mt-4 pt-1 mb-3" src="${require('../img/svg/text-header.svg')}" alt="Agora tem plano pessoa física tem tem" title="Agora tem plano pessoa física tem tem" />
        <h3 class="mb-sm-2 mb-md-4">Plano Pessoa Física</h3>
        <p class="mb-0">Para quem quer, tem. Para você e para todos.</p>
        <p>Plano individual para você se manter com a saúde em dia de forma fácil, simples e descomplicada. Quem tem unimed plano pessoa física ta com tudo.</p>
        <a id="btn-register-link">Faça agora seu plano</a>
        <div class="logo-header w-100 mt-5 d-flex justify-content-end">
          <img class="w-50" src="${require('../img/logo-unimed-patos-de-minas.png')}" alt="logomarca-unimed-patos-de-minas" title="Unimed Patos de Minas - Campanha Pessoa Física" />
        </div>
      </div>
    </div>
  </header>
  <section class="content-mobile" id="content">
    <div class="w-100 primary-content">
      <h1 class="mt-4 mb-3">Você em primeiro<br />plano.</h1>
      <span>O foco é a singularidade da vida.</span>
      <ul>
        <li>Atendimento em Patos de Minas e região;</li>
        <li>Acomodação Enfermaria e Apartamento;</li>
        <li>Super desconto gradativo conforme inclusão de dependentes;</li>
        <li>Urgência e emergência nacional;</li>
        <li>Serviço de remoção aérea incluso.</li>
      </ul>
      <h4 class="m-0 mt-5">Consulte condições contratuais com nossos consultores.</h4>
    </div>
    <div class="w-100 px-4">
      <div class="second-content">
        <h2>Um plano<br />que é a<br />sua cara.</h2>
        <div class="physical-people-content">
          <img class="mt-4" src="${require('../img/pessoa-fisica-content.png')}" alt="Plano Fácil Pessoa Física" title="Plano fácil pessoa física" />
        </div>
      </div>
    </div>
    <div class="content-lines w-100 position-relative">
      <div class="lines-bg position-absolute h-100"></div>
    </div>
  </section>
  <footer class="footer-mobile" id="contact-form">
    <div class="contact">
      <div class="d-flex">
        <div class="w-25 content-send-button">
          <img class="icon-branding" src="${require('../img/svg/icon-branding.svg')}" alt="icon-campanha-pessoa-fisica" title="Unimed Patos de Minas - Campanha Pessoa Física" />
          <h4 class="contact-text-info">Preencha seus dados que logo entraremos em contato com você.</h4>
          <button id="send-email" class="btnSubmit btn-send">Enviar</button>
        </div>
        <form class="w-100 mt-sm-0 mt-md-3" method="post" autocomplete="off" name="google-sheet">
          <div class="w-100 form-content">
            <div class="form-group">
              <span>Nome*:</span>
              <input type="text" name="Name" class="form-control text-input mt-sm-0 mt-md-2" value="" required />
            </div>
            <div class="form-group">
              <span>Email*:</span>
              <input type="email" name="Email" class="form-control text-input mt-sm-0 mt-md-2" value="" required />
            </div>
            <div class="form-group">
              <span>Cidade:</span>
              <input type="text" name="Cidade" class="form-control text-input mt-sm-0 mt-md-2" value="" required />
            </div>
            <div class="form-group">
              <span>Telefone:</span>
              <input type="number" name="Phone" class="form-control text-input mt-sm-0 mt-md-2" value="" required />
            </div>
          </div>
        </form>
      </div>
      <div class="d-flex w-100 justify-content-end">
        <p class="w-75 px-5 pt-3">*Desconto gradativo somente na inclusão de dependentes. Consulte nossos consultores para mais informações.</p>
      </div>
    </div>
    <div class="info w-100 px-4">
      <div class="w-100 d-flex flex-wrap">
        <h5 class="mb-3 mx-sm-0 mx-md-5"><b>Unimed Patos de Minas</b></h5>
        <div class="w-100 d-flex px-sm-0 px-md-5">
          <div class="w-100">
            <p class="mb-2"><b>Unidade Comercial</b></p>
            <p>Endereço: R. Olegário Maciel, 413</p>
            <p>Centro, Patos de Minas - MG</p>
            <p>Telefone: (34) 3820-1622</p>
            <div class="d-flex w-100 align-items-center mt-4">
              <img class="w-25 mt-sm-1 mt-md-3" src="${require('../img/logo-somos-coop.png')}" alt="Somos Coop" alt="Somos Coop" />
              <img class="w-25 mt-sm-4 mt-md-3" src="https://www.unimed.coop.br/servicos-compartilhados-portlet/ImagemANS?ans=353060" alt="ANS 353060" title="ANS 353060" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="logo-background w-100 position-relative mt-5">
      <img class="logo-footer position-absolute" src="${require('../img/logo-unimed-patos-de-minas.png')}" alt="logomarca-unimed-patos-de-minas" title="Unimed Patos de Minas - Campanha Pessoa Física" />
    </div>
  </footer>
</main>
`

export default mobile