import * as $ from 'jquery'
import swal from 'sweetalert'

const url = 'https://script.google.com/macros/s/AKfycbx6GKXssKocRS0J4Kpa6v9_Qxwm3nEGEbjM2ZiIzssBaBTeMJ8_/exec'

$(document).on('click', '#send-email', function(e) {
  e.preventDefault()

  console.log($("form[name='google-sheet'] input[name='Email']").val())

  if ((!$("form[name='google-sheet'] input[name='Email']").val()) && (!$("form[name='google-sheet'] input[name='Name']").val())){
    swal ( "Oops", "Não foi possível enviar, preencha os dados corretamente", "error" )
  } else {
    fetch(url, { mode: 'no-cors', method: 'POST', body: new FormData($("form[name='google-sheet']")[0]) })
      .then(response => swal("Obrigado", "Dados enviados com sucesso!", "success"))
      .catch(error => console.error('Error!', error.message))
  }
})
  
