import * as $ from 'jquery';

$(document).on('click', '#btn-initial', function() {
  $('html, body').animate({
      scrollTop: $("#initial").offset().top
  }, 200);
});

$(document).on('click', '#btn-advantages', function() {
  $('html, body').animate({
      scrollTop: $("#content").offset().top
  }, 200);
});

$(document).on('click', '#btn-register-link', function() {
  $('html, body').animate({
      scrollTop: $("#contact-form").offset().top
  }, 200);
});

$(document).on('click', '#btn-register', function() {
  $('html, body').animate({
      scrollTop: $("#contact-form").offset().top
  }, 200);
});

$(window).on('scroll', function() {
  const scroll = $(window).scrollTop();

  if (scroll >= 100) {
    $('.back-menu').addClass('add-color-menu');
  } else {
    $('.back-menu').removeClass('add-color-menu');
  }
});